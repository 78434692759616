<template>
  <b-overlay
    :show="isLoading"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-header">
        <div class="d-flex flex-row justify-content-between align-items-start w-100">
          <div class="card-title">
            ระบบจัดการประกาศ
          </div>
          <div class="d-flex flex-column justify-content-center align-items-end">
            <Create
              :game-categories="gameCategories"
              :game-products="gameProducts"
              :games="games"
              @getGames="getGames"
              @getProducts="getGameProducts"
              @submit="upsert"
            />
            <b-form-input
              class="mt-1"
              placeholder="ค้นหา"
              @input="search"
            />
          </div>
        </div>
      </div>
      <b-table-simple
        striped
        hover
        small
        responsive
        show-empty
        class="mt-2 position-relative items-center"
        :per-page="perPage"
        :fields="fields"
        fixed
      >
        <b-thead>
          <b-tr>
            <b-th>รูปภาพ</b-th>
            <b-th>ชื่อ</b-th>
            <b-th>สถานะ</b-th>
            <b-th>รายละเอียด</b-th>
            <b-th>วันที่ประกาศ</b-th>
            <b-th>#</b-th>
          </b-tr>
        </b-thead>
        <draggable
          v-model="items.data"
          tag="tbody"
          @start="drag = true"
          @end="dragend"
        >
          <b-tr
            v-for="item in items.data"
            :key="item.id"
          >
            <b-td>
              <img
                :src="item.image && agent ? `${agent.site_admin}/img/announcements/${item.image}` : item.image_link"
                alt="Image"
                width="150"
              >
            </b-td>
            <b-td>{{ item.title_th }}</b-td>
            <b-td><b-form-checkbox
              v-model="item.status"
              name="check-button"
              switch
              @change="changeStatus(item)"
            /></b-td>
            <b-td>{{ item.description }}</b-td>
            <b-td>{{ item.created_at | dateFormat }}</b-td>
            <b-td><b-dropdown
              id="dropdown-3"
              text="Success"
              variant="success"
            >
              <template #button-content>
                <i class="far fa-edit mr-50" /> จัดการ
              </template>

              <Edit
                :data="item"
                :game-categories="gameCategories"
                :game-products="gameProducts"
                :games="games"
                @getGames="getGames"
                @getProducts="getGameProducts"
                @submit="upsert"
              />
              <b-dropdown-item @click="destroy(item.id)"><i class="fas fa-trash mr-1 text-danger" /> ลบ
              </b-dropdown-item>
            </b-dropdown></b-td>
          </b-tr>
        </draggable>
        <!-- <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
    'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(image_link)="{ item }">
          <img :src="item.image && agent ? `${agent.site_admin}/img/announcements/${item.image}` : item.image_link" alt="Image" width="150">
        </template>
        <template #cell(created_at)="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template #cell(status)="{ item }">
          <b-form-checkbox v-model="item.status" name="check-button" switch @change="changeStatus(item)"></b-form-checkbox>
        </template>
        <template #cell(actions)="{ item }">
          <b-dropdown id="dropdown-3" text="Success" variant="success">
            <template #button-content>
              <i class="far fa-edit mr-50" /> จัดการ
            </template>

            <Edit @getGames="getGames" @getProducts="getGameProducts" :data="item" @submit="upsert" :gameCategories="gameCategories" :gameProducts="gameProducts" :games="games"/>
            <b-dropdown-item @click="destroy(item.id)"><i class="fas fa-trash mr-1 text-danger" /> ลบ
            </b-dropdown-item>
          </b-dropdown>
        </template> -->
      </b-table-simple>
      <div class="card-body d-flex justify-content-between flex-wrap pt-0">
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getData()"
          />
        </b-form-group>

        <div>
          <b-pagination
            v-model="page"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getItems('')"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormCheckbox, BFormSelect, BFormGroup, BPagination, BDropdownItem, BDropdown, BButton, BTable, BOverlay, BIconController, BFormInput,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import * as _ from 'lodash'
import draggable from 'vuedraggable'
import Edit from './edit.vue'
import Create from './create.vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BPagination,
    BDropdownItem,
    BDropdown,
    BButton,
    BTable,
    BOverlay,
    BIconController,
    BFormInput,
    Create,
    Edit,
    draggable,
  },
  filters: {
    dateFormat(val) {
      return moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
    },
  },
  data: () => ({
    // drag: [],
    isLoading: false,
    perPage: 10,
    pageOptions: [10, 15, 20],
    totalRows: 1,
    page: 1,
    fields: [
      { key: 'image_link', label: 'รูปภาพ' },
      { key: 'title_th', label: 'ชื่อ' },
      { key: 'status', label: 'สถานะ' },
      { key: 'description', label: 'รายละเอียด' },
      { key: 'created_at', label: 'วันที่ประกาศ' },
      { key: 'actions', label: '#' },
    ],
    items: {
      data: [],
    },
    debouncedSearch: '',
    gameCategories: [],
    gameProducts: [],
    games: [],
  }),
  computed: {
    agent() {
      return this.$store.state.app.agent
    },
  },
  computed: {
    search: {
      get() {
        return this.debouncedSearch
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.debouncedSearch = val
        }, 400)
      },
    },
  },
  mounted() {
    this.getItems('')
    this.getGameCategories()
  },
  methods: {
    async dragend() {
      this.isLoading = true
      try {
        await this.$http.post('/announcements/drag', { data: this.items.data })

        this.getItems('')
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getItems(term) {
      this.isLoading = true

      try {
        const { data } = await this.$http.get('/announcements', {
          params: {
            term,
          },
        })
        const mapData = data
        mapData.data = data.data.map(item => {
          item.status = item.status === 1
          return item
        })
        this.items = mapData
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getGameCategories() {
      this.isLoading = true

      try {
        const { data } = await this.$http.get('/games/categories')
        this.gameCategories = data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getGameProducts(e) {
      try {
        const { data } = await this.$http.get(`/games/products/${e}`)
        this.gameProducts = data
      } catch (error) {
        console.log(error)
      }
    },
    async getGames(e) {
      try {
        const { data } = await this.$http.get(`/games/games/${e}`)
        this.games = data
      } catch (error) {
        console.log(error)
      }
    },
    async upsert(form) {
      this.isLoading = true

      const fd = new FormData()

      fd.append('id', form.id)
      fd.append('title_th', form.title_th)
      fd.append('description', form.description)
      if (form.image_link) {
        fd.append('image_link', form.image_link)
      }
      fd.append('image', form.file)
      fd.append('game_category_id', form.game_category_id)
      fd.append('game_product_id', form.game_product_id)
      fd.append('game_id', form.game_id)
      // fd.append('status', form.status);

      try {
        const { data } = await this.$http.post('/announcements', fd)

        this.getItems('')
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async changeStatus(item) {
      this.isLoading = true

      try {
        const { data } = await this.$http.post('/announcements/changeStatus', item)

        this.getItems('')
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async destroy(id) {
      this.isLoading = true

      try {
        const { data } = await this.$http.delete(`/announcements/${id}`)

        this.getItems('')
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    search: _.debounce(function (val) {
      this.getItems(val)
    }, 400),
  },
}
</script>
